import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import DialogInfo from '../Dialog/DialogInfo'
import GQL from '../GQL';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';



const ResetPassword = (props) => {
    const email = props.match.params.email
    const salt = props.match.params.salt
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [password, setPassword] = useState()
    const handleChange = (ev) => {
        setPassword(ev.target.value)
    }
    const handleReset = () => {
        console.log(password)
        const query = `
        mutation{
            resetPasswordMember(salt:"${salt}",email:"${email}", password:"${password}")
          }
        `
        GQL(query, null, null).then(res => {
            if (res.errors) throw res.errors;
            setOpenConfirmInfo(true)
            setDialogtext(`<p>Password anda berhasil di reset<p/>`)
            setDialogUrl(`/login/`)
        }).catch(err => {
            setOpenConfirmInfo(true)
            setDialogtext(`<p>Password anda tidak dapat di reset saat ini. Harap coba lagi nanti !<p/>`)
        })
    }
    return (
        <Row>
            <Col xs={12}>
                <h6 className="text-center">Hello {email}</h6>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Masukan Password Baru Anda"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChange}
                />
                <Button
                    fullWidth
                    variant="contained"
                    className="submit"
                    onClick={handleReset}
                >
                    Reset Password
          </Button>
            </Col>
            {OpenConfirmInfo && (
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            )}
        </Row>
    );
}

export default ResetPassword;