import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import mRegister from './Register.controller'
import DialogInfo from '../../Dialog/DialogInfo'
import { Col, Row } from 'react-bootstrap';
import { TextField, Select, FormControl, FormControlLabel, MenuItem, InputLabel, Checkbox } from '@material-ui/core';
import listProvince from '../../Components/Constants/Province'
import Kota from '../../Components/Constants/Kota'
import ForgetPassword from '../../Dialog/ForgetPassword';
const { listKota } = Kota;
function MadeWithLove() {
    if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
        return (
            <Typography variant="body2" align="center">
                {'Developed by: '}
                <a target="_blank" color="inherit" className="colorLogin" href="https://homansystem.com/">
                    Homan System
          </a>
                {' team.'}
            </Typography>
        );
    } else if (process.env.REACT_APP_COMPANY == "Ipos") {
        return (
            <Typography variant="body2" align="center">
                {'Developed by: '}
                <a target="_blank" color="inherit" className="colorLogin" href="https://www.ipos.co.id/">
                    iPOS
          </a>
                {' team.'}
            </Typography>
        );
    }

}

const Register = (props) => {
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isForget, setIsForget] = useState(false)
    const [state, setState] = useState({
        email: '',
        password: '',
        namaDepan: '',
        namaBelakang: '',
        nohp: '',
        nik: '',
        province: '',
        city: '',
        alamat: '',
        kodepos: '',
        gender: '',
        isReceiveMarketingEmail: false
    })

    const handleForget = () => {
        setIsForget(true)
    }
    const handleForgetInfo = (info) => {
        setIsForget(false)
        setOpenConfirmInfo(true)
        setDialogtext(info)
    }
    const onRegister = (ev) => {
        const { email, password, namaDepan, namaBelakang, nohp, nik, province, city, alamat, kodepos, gender, isReceiveMarketingEmail } = state;
        mRegister.register(email, password, namaDepan, namaBelakang, nohp, nik, province, city, alamat, kodepos, gender, isReceiveMarketingEmail).then((val) => {
            if (val) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Registrasi Anda berhasil. Buka E-mail anda untuk mengaktifkan akun anda<p/>`)
                setDialogUrl(`/login`)
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Server tidak dapat menjalankan permintaan Anda. Harap coba lagi beberapa saat lagi<p/>`)
            }
        }).catch(err => {
            console.log(err);
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${err[0].message}<p/>`)
        })
        ev.preventDefault();
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...state };
        newValue[key] = value;
        setState(newValue);
    }
    const handleChangeCheked = (ev) => {
        var value = ev.target.checked;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...state };
        newValue[key] = value;
        setState(newValue);
    }
    const getKota = () => {
        if (state.province && listKota[state.province]) return listKota[state.province];
        return [];
    }
    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: '-70px' }}>
            <CssBaseline />
            <div className="paper">
                <Avatar className="avatar">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">Sign Up</Typography>
                {/* <form className="form" onSubmit={onRegister}> */}
                <form className="form">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={state.email}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={state.password}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="namaDepan"
                        label="Nama Depan"
                        name="namaDepan"
                        autoComplete="namaDepan"
                        value={state.namaDepan}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="namaBelakang"
                        label="Nama Belakang"
                        name="namaBelakang"
                        autoComplete="namaBelakang"
                        value={state.namaBelakang}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nohp"
                        label="No Handphone"
                        name="nohp"
                        autoComplete="nohp"
                        type="tel"
                        value={state.nohp}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nik"
                        label="NIK"
                        name="nik"
                        autoComplete="nik"
                        type="text"
                        value={state.nik}
                        onChange={handleChange}
                    />
                    <br /><br />
                    <FormControl required fullWidth={true} variant="outlined">
                        <InputLabel>Propinsi</InputLabel>
                        <Select
                            name="province"
                            value={state.province}
                            required
                            onChange={handleChange}
                        >
                            {listProvince.map((el, idx) => (
                                <MenuItem key={idx.toString()} value={el}>{el}</MenuItem>
                            ))}
                        </Select>
                    </FormControl><br /><br />
                    <FormControl required fullWidth={true} variant="outlined">
                        <InputLabel>Kota</InputLabel>
                        <Select
                            name="city"
                            value={state.city}
                            required
                            onChange={handleChange}
                        >
                            {getKota().map((el, idx) => (
                                <MenuItem key={idx.toString()} value={el}>{el}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="alamat"
                        label="Alamat"
                        name="alamat"
                        autoComplete="alamat"
                        rows={3}
                        multiline={true}
                        value={state.alamat}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="kodepos"
                        label="Kode pos"
                        name="kodepos"
                        autoComplete="kodepos"
                        type="tel"
                        value={state.kodepos}
                        onChange={handleChange}
                    />
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="gender"
                        onChange={handleChange}
                        value={state.gender}
                    >
                        <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                        <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                    </RadioGroup>
                    <FormControlLabel control={<Checkbox checked={state.isReceiveMarketingEmail || false} onChange={handleChangeCheked} />} id="isReceiveMarketingEmail" name="isReceiveMarketingEmail" label="Receive Marketing Email" />
                    <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        className="submit"
                        onClick={onRegister}
                    >Sign Up</Button>
                    <Grid container>
                        <Grid item xs>
                            <Link  onClick={handleForget} className="colorLogin" variant="body2">Forgot password?</Link>
                        </Grid>
                        <Grid item>
                            <Link to="login" className="colorLogin" variant="body2">
                                {"Have an account? Sign In"}
                            </Link>
                        </Grid>
                    </Grid>

                </form>
            </div>
           
            <Box mt={5}>
                <MadeWithLove />
            </Box>
            {isForget && (
                <ForgetPassword open={isForget} setOpen={setIsForget} info={handleForgetInfo} />
            )}
            {OpenConfirmInfo && (
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            )}
        </Container>
    );
}

export default Register;