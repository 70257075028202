import GQL from '../../GQL';
const kategori = {
    loadData: (clientID, token, tokenOutlet, kodeOutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listOpnamehph(kodeoutlet:"${kodeOutlet}", idoutlet:"${tokenOutlet}"){
                        notrans
                        userin
                        tglInput
                        Statname
                        status
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listOpnamehph) return (resolve(resp.data.listOpnamehph));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    opnameDetailItem: (clientID, token, kodeOutlet, kodetrans) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listBarangOpname(kodeoutlet:"${kodeOutlet}", kodetrans:"${kodetrans}"){
                        urut
                        kodebarang
                        namabarang
                        minimum
                        stok
                        selisih
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listBarangOpname) return (resolve(resp.data.listBarangOpname));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    createTransOpname: (clientID, token, kodeOutlet, tokenOutlet, kodetrans, tanggal) => {
        return new Promise((resolve, reject) => {
            const query = `
                    mutation{
                        createTransOpname(kodeoutlet:"${kodeOutlet}",idoutlet: "${tokenOutlet}" ,kodetrans:"${kodetrans}", tanggal: "${tanggal}")
                    }
                `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.createTransOpname != undefined) {
                    return (resolve(resp.data.createTransOpname));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    CancelTransOpname: (clientID, token, kodeOutlet, kodetrans) => {
        return new Promise((resolve, reject) => {
            const query = `
                    mutation{
                        CancelTransOpname(kodeoutlet:"${kodeOutlet}",kodetrans:"${kodetrans}")
                    }
                `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.CancelTransOpname != undefined) {
                    return (resolve(resp.data.CancelTransOpname));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    SaveTransOpname: (clientID, token, kodeOutlet, kodetrans) => {
        return new Promise((resolve, reject) => {
            const query = `
                    mutation{
                        SaveTransOpname(kodeoutlet:"${kodeOutlet}",kodetrans:"${kodetrans}")
                    }
                `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.SaveTransOpname != undefined) {
                    return (resolve(resp.data.SaveTransOpname));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    UpdateSrokOpname: (clientID, token, kodeOutlet, urut, stok, selisih) => {
        return new Promise((resolve, reject) => {
            const query = `
                    mutation{
                        UpdateStokOpname(kodeoutlet:"${kodeOutlet}",urut: ${urut}, stok:${stok}, selisih: ${selisih})
                    }
                `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.UpdateStokOpname != undefined) {
                    return (resolve(resp.data.UpdateStokOpname));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default kategori;