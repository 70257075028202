import React, { useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, DialogActions, DialogTitle, DialogContent, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AuthContext from '../../context/auth-context'
import { isArray } from "util";
import mOpname from './Opname.controller'
import ModalInfo from '../../Dialog/Modal.Info'

const ModalEditQty = (props) => {
    const classes = useStyles();
    const minimum = props.detailItem.minimum
    const urut = props.detailItem.urut
    const [stok, setStok] = useState(props.detailItem.stok)
    const [selisih, setSelisih] = useState(props.detailItem.selisih)
    const { clientID, token, kodeOutlet } = useContext(AuthContext)
    const [modalText, setModalText] = useState()
    const [modalInfoOpen, setModalInfoOpen] = useState(false)
    const handleClose = () => {
        props.setOpen(false);
    }
    const handleOke = () => {
        mOpname.UpdateSrokOpname(clientID, token, kodeOutlet, urut, stok, selisih).then(resp => {
            if (resp) {
                props.setOpen(false);
                props.oke(true)
            } else {
                setModalInfoOpen(true)
                setModalText(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }

        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setModalInfoOpen(true)
                setModalText(`<p>Gagal Add Opname,  ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                alert("eror")
                setModalInfoOpen(true)
                setModalText(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        });
    }
    const handleChange = (val) => {
        setStok(val.target.value)
        setSelisih(minimum - val.target.value)
    }
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleOke()
        }
    }
    return (
        <React.Fragment>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={`${classes.paper} bgModal`}>
                        <DialogTitle className="colorWhite">Ubah Stok {props.detailItem.namabarang}</DialogTitle>
                        <DialogContent className="colorWhite">
                            <TextField
                                name="stok"
                                fullWidth
                                value={stok}
                                autoFocus
                                variant="outlined"
                                onChange={handleChange}
                                type="number"
                                onKeyPress={handleEnter}
                            />
                        </DialogContent>
                        <DialogActions>
                            {}
                            <Button onClick={handleClose} color="primary">Batal</Button>
                            <Button onClick={handleOke} color="primary">Oke</Button>
                        </DialogActions>
                    </div>
                </Fade>
            </Modal>

            {modalInfoOpen && (
                <ModalInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={modalText} />
            )}
        </React.Fragment>
    )
}
export default ModalEditQty;

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 350,
        boxShadow: theme.shadows[5],
        padding: 0,
    },
}));
