import GQL from '../../GQL'

const pilihoutlet = {
    loadData: (userlogin, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    listMember{
                        email
                        firstName
                        lastName
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listMember && resp.data.listMember) return (resolve(resp.data.listMember));
                return (resolve([]))
            }).catch(err => reject(err))
        })
    },
    loginAsCustomer: (clientID, token, email) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    loginMemberMaster(email:"${email}"){
                        clientID
                        token
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.loginMemberMaster && resp.data.loginMemberMaster) return (resolve(resp.data.loginMemberMaster));
                return (resolve([]))
            }).catch(err => reject(err))
        })
    },
}

export default pilihoutlet;