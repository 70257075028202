import React from "react";
import {
    Button, Dialog, Slide, DialogTitle, DialogActions, DialogContent, DialogContentText
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const DialogConfirm = (props) => {
    const handleClose = () => {
        props.setOpen(false);
    }
    const handleOke= ()=>{
        props.oke(props.data)
    }
    const theme = useTheme();

    return (
        <Dialog open={props.open} TransitionComponent={Transition}>
            <DialogTitle className="colorWhite">iPOS Opname</DialogTitle>
            <DialogContent className="colorWhite">
                    <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleOke} color="primary" autoFocus>
                    Oke
          </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DialogConfirm;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});