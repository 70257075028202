import React, { useState, useContext } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AuthContext from '../../context/auth-context';
import ForgetPassword from '../../Dialog/ForgetPassword';
import DialogInfo from '../../Dialog/DialogInfo';

function MadeWithLove() {
  if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
    return (
      <Typography variant="body2" align="center">
        {'Developed by: '}
        <a target="_blank" color="inherit" className="colorLogin" href="https://homansystem.com/">
          Homan System
        </a>
        {' team.'}
      </Typography>
    );
  } else if (process.env.REACT_APP_COMPANY == "Ipos") {
    return (
      <Typography variant="body2" align="center">
        {'Developed by: '}
        <a target="_blank" color="inherit" className="colorLogin" href="https://www.ipos.co.id/">
          iPOS
        </a>
        {' team.'}
      </Typography>
    );
  }

}


const Login = (props) => {

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [isForget, setIsForget] = useState(false)
  const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
  const [dialogtext, setDialogtext] = useState()
  const { login } = useContext(AuthContext);

  const handleUsername = (val) => setUsername(val.target.value);

  const handlePassword = (val) => setPassword(val.target.value);

  const handleLogin = (ev) => {
    login(username, password);

    ev.preventDefault();
  }
  const handleForget = () => {
    setIsForget(true)
  }
  const handleForgetInfo = (info) => {
    setIsForget(false)
    setOpenConfirmInfo(true)
    setDialogtext(info)
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper">
        <Avatar className="avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className="form" onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleUsername}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePassword}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="submit"
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link className="colorLogin" onClick={handleForget} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link className="colorLogin" to="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
      {isForget && (
        <ForgetPassword open={isForget} setOpen={setIsForget} info={handleForgetInfo} />
      )}
      {OpenConfirmInfo && (
        <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
      )}
    </Container>
  );
}

export default Login;