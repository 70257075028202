import React from 'react';

export default React.createContext({
  clientID: null,
  token: null,
  tokenOutlet: null,
  kodeOutlet: null,
  userLogin : null,
  login: (username,password) => {},
  logout: ()=>{}
})