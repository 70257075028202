import React, { useEffect, useReducer, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context';
import { useCookies } from 'react-cookie';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register'
import Aktivasi from './Components/Aktivasi'
import Home from './Components/Home/Home';
import ResetPassword from './Components/ResetPassword'
import Navbar from './Navbar/Navbar'
import GQL from './GQL'
import DialogInfo from './Dialog/DialogInfo'
import MasterUser from './Master/User/User'
import Opname from './Components/Opname/Opname'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: window.innerWidth - 200
  },
}));

const initialValue = {
  token: null,
  tokenOutlet: null,
  kodeOutlet: null,
  clientID: null,
  userLogin: null,
  isCookieLoad: false,
  isLogin: false
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        tokenOutlet: action.tokenOutlet,
        kodeOutlet: action.kodeOutlet,
        clientID: action.clientID,
        userLogin: action.userLogin,
        isLogin: true
      }
    case "LOGOUT":
      return {
        ...state,
        token: null,
        tokenOutlet: null,
        kodeOutlet: null,
        clientID: null
      }
    case "COOKIE":
      return {
        ...state,
        token: action.token,
        tokenOutlet: action.tokenOutlet,
        kodeOutlet: action.kodeOutlet,
        clientID: action.clientID,
        userLogin: action.userLogin,
        isCookieLoad: true
      }
    default: throw new Error("Invalid Action");
  }
}

function App(props) {
  const [state, dispatch] = useReducer(loginReducer, initialValue)
  const [cookies, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COMPANY}-opname`]);
  const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
  const [dialogtext, setDialogtext] = useState()
  const classes = useStyles();
  useEffect(() => {
    var render = true;
    if (cookies[`${process.env.REACT_APP_COMPANY}-opname-auth`]) {
      const { clientID, token, userLogin, tokenOutlet, kodeOutlet } = cookies[`${process.env.REACT_APP_COMPANY}-opname-auth`] || { clientID: null, token: null, userLogin: null, tokenOutlet: null };
      if (render) {
        //console.log(clientID,token);
        dispatch({ type: "COOKIE", clientID, token, userLogin, tokenOutlet, kodeOutlet})
      }
    } else {
      dispatch({ type: "COOKIE", clientID, token, userLogin })

    }
    return () => {
      render = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[`${process.env.REACT_APP_COMPANY}-opname-auth`]]);

  const login = (username, password) => {
    const userLogin = username
    const query = `
      query{
        loginOpname(username:"${username}",password:"${password}"){
          clientID
          token
          tokenOutlet
          kodeOutlet
        }
      }
    `
    GQL(query).then(res => {
      if (res.errors) throw res.errors;
      const token = res.data.loginOpname.token
      const clientID = res.data.loginOpname.clientID
      const tokenOutlet = res.data.loginOpname.tokenOutlet
      const kodeOutlet = res.data.loginOpname.kodeOutlet
      setCookie(`${process.env.REACT_APP_COMPANY}-opname-auth`, { clientID, token, userLogin, tokenOutlet, kodeOutlet }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
      dispatch({ type: "LOGIN", clientID, token, userLogin, tokenOutlet, kodeOutlet })
    }).catch(err => {
      if (err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    })
  }

  const logout = () => {
    const query = `
                mutation {
                    logoutOpname
                }
            `
    GQL(query, state.clientID, state.token).then(resp => {
      if (resp.errors) throw resp.errors;
      if (resp.data && resp.data.logoutOpname) {
        dispatch({ type: "LOGOUT" })
        setCookie(`${process.env.REACT_APP_COMPANY}-opname-auth`, { clientID, token, userLogin }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
        removeCookie(`${process.env.REACT_APP_COMPANY}-opname-auth`)
      } else {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>Gagal Logout<p/>`)
      }
    }).catch(err => {
      console.log(err)
      if (err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    })
  }
  const { clientID, token, isCookieLoad, isLogin, userLogin, tokenOutlet,kodeOutlet } = state;


  // console.log(state)
  return (
    <Router>
      <React.Fragment>
        <AuthContext.Provider
          value={{
            token,
            clientID,
            login,
            logout,
            userLogin,
            tokenOutlet,
            kodeOutlet
          }}
        >
          <div className={classes.root} >
            <CssBaseline />
            <Navbar onLogout={logout} isLogin={(token && clientID)} />
            <main className={classes.content}>
              <Switch>
                <Route path="/aktivasi/:salt" component={Aktivasi} />
                <Route path="/reset/:email/:salt" component={ResetPassword} />
                {(clientID && token && isCookieLoad) &&
                  <React.Fragment>
                    <Route path="/" exact component={Home} />
                    {/* Opname */}
                    <Route path="/opname/:page/:rowsPerPage" exact component={Opname} />

                    {/* Master Data */}
                    {isLogin && <Redirect from="/login/" to="/" exact />}
                    <Route path="/user" exact component={MasterUser} />
                  </React.Fragment>

                }
                {((!clientID || !token) && isCookieLoad) &&
                  (
                    <React.Fragment>
                      <Route path="/login/" component={Login} />
                      <Route path="/register/" component={Register} />
                      <Redirect to="/login/" />

                    </React.Fragment>
                  )
                }

              </Switch>
            </main>
          </div>
        </AuthContext.Provider>
        <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
      </React.Fragment>

    </Router>
  );
}

export default App;
