import React, { useContext, useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, TextField, InputBase
} from '@material-ui/core';
import { Row, Col, Table } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import AuthContext from '../../context/auth-context'
import mOpname from './Opname.controller'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import SearchIcon from '@material-ui/icons/Search'
import ModalConfirm from '../../Dialog/Modal.Confirm'
import ModalInfo from '../../Dialog/Modal.Info'
import ModalEditStok from './Modal.EditStok'
import { isArray } from "util";
import QrReader from 'react-qr-reader'
import CropFreeIcon from '@material-ui/icons/CropFree';
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment-timezone'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';




const OpnameDetail = (props) => {
    const classes = useStyles();
    const kodetrans = props.dataTrans.kodetrans
    const [tanggal, settanggal] = useState(props.dataTrans.tanggal)
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token, kodeOutlet } = useContext(AuthContext)
    const [listItem, setListItem] = useState([])
    const [listItemOri, setlistItemOri] = useState([])
    const [search, setSearch] = useState(null)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
    const [modalText, setModalText] = useState()
    const [aksiModal, setAksiModal] = useState()
    const [detailItem, setDetailItem] = useState({})
    const [modalInfoOpen, setModalInfoOpen] = useState(false)
    const [modalEditStokOpen, setModalEditStokOpen] = useState(false)
    const [scanQR, setScanQR] = useState('No Result')
    const [openScanQr, setOpenScanQr] = useState(false)
    const [scanBarcode, setScanBarcode] = useState('No Result')
    const handleClose = () => {
        props.setOpen(false);
    }

    const handleChangeTanggal = (ev) => {
        settanggal(ev)
    }
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mOpname.opnameDetailItem(clientID, token, kodeOutlet, kodetrans).then(resp => {
                setListItem(resp)
                setlistItemOri(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])
    const searchHandler = (val) => {
        setSearch(val.target.value)
    }

    const formCariHandler = (event) => {
        if (event.key === 'Enter') {
            if (search) {
                let hasil = listItem.filter(el => {
                    return el.namabarang.toUpperCase().match(search.toUpperCase())
                })
                setListItem(hasil)
            } else {
                setListItem(listItemOri)
            }
        }
    }

    const handleSimpanConfirm = () => {
        setModalConfirmOpen(true)
        setModalText(`<p>Yakin ingin simpan transaksi ini?</p>`)
        setAksiModal("isSimpan")
    }
    const handleSimpan = () => {
        mOpname.SaveTransOpname(clientID, token, kodeOutlet, kodetrans).then(resp => {
            if (resp) {
                props.setOpen(false);
                props.isRefrest(true)
            } else {
                setModalInfoOpen(true)
                setModalText(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }

        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setModalInfoOpen(true)
                setModalText(`<p>Gagal Add Opname,  ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                alert("eror")
                setModalInfoOpen(true)
                setModalText(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        });
    }
    const handleCancelConfirm = () => {
        setModalConfirmOpen(true)
        setModalText(`<p>Yakin ingin cancel transaksi ini?</p>`)
        setAksiModal("isBatal")
    }
    const handleCancel = () => {
        mOpname.CancelTransOpname(clientID, token, kodeOutlet, kodetrans).then(resp => {
            if (resp) {
                props.setOpen(false);
                props.isRefrest(true)
            } else {
                setModalInfoOpen(true)
                setModalText(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }

        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setModalInfoOpen(true)
                setModalText(`<p>Gagal Add Opname,  ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                alert("eror")
                setModalInfoOpen(true)
                setModalText(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        });
    }
    const handleAksiModal = () => {
        if (aksiModal === 'isSimpan') {
            handleSimpan()
        } else if (aksiModal === 'isBatal') {
            handleCancel()
        }
    }
    const handleEditStok = (data) => {
        setDetailItem(data)
        setModalEditStokOpen(true)
    }
    const handleScan = data => {
        setScanQR(data)
        var kodebarangScan
        if (data !== "No Result" && data !== null) {
            kodebarangScan = data
            let hasil = listItem.filter(el => {
                return el.kodebarang === kodebarangScan
            })
            if (hasil.length > 0) {
                setOpenScanQr(false)
                setListItem(hasil)
                handleEditStok(hasil[0])
            } else {
                setModalInfoOpen(true)
                setModalText(`<p>Data tidak ditemukan<p/>`)
            }
        } else {
            setListItem(listItemOri)
        }
    }
    const handleError = err => {
        setModalInfoOpen(true)
        setModalText(`<p>${err.message}<p/>`)
    }
    const renderScanQR = () => {
        setOpenScanQr(true)
    }
    const closeScanQR = () => {
        setListItem(listItemOri)
        setOpenScanQr(false)
    }


    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = `Opname ${kodetrans} ${moment(tanggal).format("DD-MMM-YYYY")}`;
        const headers = [["Barang", "Stok", "Selisih"]];

        const data = listItem.map(elt => [elt.namabarang, elt.stok, elt.selisih]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Opname ${kodetrans} ${moment(tanggal).format("DD-MM-YYYY")}.pdf`)
    }
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Opname Detail </Typography>
                    {props.dataTrans.status === "1" && (
                        <Button color="inherit" onClick={handleCancelConfirm}>Cancel</Button>
                    )}
                    <Button color="inherit" onClick={handleSimpanConfirm}>save</Button>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row>
                    <Col xs={12} style={{ marginTop: 10 }}>
                        <TextField
                            id="kodetrans"
                            name="kodetrans"
                            label="Kode Trans"
                            fullWidth
                            value={kodetrans}
                            disabled
                        />
                    </Col>
                    <Col xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                            <KeyboardDatePicker
                                fullWidth={true}
                                margin="normal"
                                id="tanggal"
                                label="Tanggal"
                                format="DD-MMM-YYYY"
                                value={tanggal}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ color: '#000 !important' }}
                                onChange={handleChangeTanggal}
                                ampm={false}
                                disabled
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {/* <Button variant="contained" color="primary" onClick={bukaScanBarcode}><CropFreeIcon />Scan Barocde</Button> */}
                        {!openScanQr && (
                            <Button variant="contained" color="primary" onClick={renderScanQR}><CropFreeIcon />Scan QR</Button>

                        )}
                        {openScanQr && (
                            <React.Fragment>
                                <QrReader
                                    delay={300}
                                    onError={handleError}
                                    onScan={handleScan}
                                    style={{ width: '100%' }}
                                />
                                <Button variant="contained" color="primary" onClick={closeScanQR}><CropFreeIcon />Tutup Scan QR</Button>
                            </React.Fragment>
                        )}
                    </Col>
                    <Col xs={12} style={{ marginTop: 1 }}>
                        <Button variant="contained" color="primary"  onClick={() => exportPDF()}><PictureAsPdfIcon />Export PDF</Button>
                    </Col>
                    <Col xs={12} style={{ marginTop: 1 }}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase onChange={searchHandler} onKeyPress={formCariHandler}
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </Col>
                    <Col xs={12} className={`${classes.contentBody} bodySessionDetail`}>
                        <Table striped bordered hover responsive size="sm">
                            <thead>
                                <tr className="colorWhite bgHeaderTabel tabelCenter">
                                    <th style={{ minWidth: '200px' }}>Barang</th>
                                    {/* <th>Min</th> */}
                                    <th>Stok</th>
                                    <th>Selisih</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listItem.map((data, index) => {
                                    return (
                                        <tr className="colorWhite" key={index} onClick={handleEditStok.bind(this, data)}>
                                            <td>
                                                {data.namabarang}
                                            </td>
                                            {/* <td className="text-center">
                                                {data.minimum}
                                            </td> */}
                                            <td className="text-center">
                                                {data.stok}
                                            </td>
                                            <td className="text-center">
                                                {data.selisih}
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            {modalConfirmOpen && (
                <ModalConfirm open={modalConfirmOpen} setOpen={setModalConfirmOpen} text={modalText} oke={handleAksiModal} />
            )}
            {modalEditStokOpen && (
                <ModalEditStok open={modalEditStokOpen} setOpen={setModalEditStokOpen} detailItem={detailItem} oke={setIsRefresh} />
            )}
            {modalInfoOpen && (
                <ModalInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={modalText} />
            )}
        </Dialog>
    )
}
export default OpnameDetail;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    contentBody: {
        height: window.innerHeight - 200,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#eb6f65',
        '&:hover': {
            backgroundColor: '#eb6f65',
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        float: 'right',
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        color: '#fff !important'
    },
}));