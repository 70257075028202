/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, InputBase, Checkbox, Toolbar, Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import mOpname from './Opname.controller'
import { FaChevronLeft } from 'react-icons/fa'
import useStyles from '../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import DialogConfirm from '../../Dialog/DialogConfirm'
import DialogInfo from '../../Dialog/DialogInfo'
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone'
import PageviewIcon from '@material-ui/icons/Pageview';
import OpnameDetail from './OpnameDetail'
import { isArray } from "util";

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const Opname = (props) => {
    var defaultPage = parseInt(props.match.params.page, 10) - 1;
    var defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token, tokenOutlet, kodeOutlet } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("jamin");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(defaultPage);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [rows, setRows] = useState([])
    const [isRefresh, setIsRefresh] = useState(true)
    const [noData, setNoData] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openInfo, setOpenInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [openOpnameDetail, setOpenOpnameDetail] = useState(false)
    const [openDataTrans, setOpenDataTrans] = useState({})
    const goBack = () => {
        props.history.push(`/`)
    }
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mOpname.loadData( clientID, token, tokenOutlet, kodeOutlet).then(resp => {
                if (render) setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeOutlet, clientID, token, isRefresh]);


    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];

    const headRows = [
        {
            id: "notrans", numeric: false, disablePadding: true, label: "No Trans"
        },
        { id: "jamin", numeric: true, disablePadding: false, label: "Tanggal" },
        { id: "userin", numeric: true, disablePadding: false, label: "User" },
        { id: "Statname", numeric: true, disablePadding: false, label: "Status" },

    ];

    const EnhancedTableHead = (props) => {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: ' 15px' }}>Action</TableCell>
                    {headRows.map(row => (
                        <TableCell key={row.id} style={{ width: '15px' }}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };
    const konfirmasiaddTrans = (data) => {
        setOpenConfirm(true);
        setDialogtext(`<p>Yakin Anda Ingin Membuat Transaksi Baru?<p/>`)
    }
    const addTrans = () => {
        setOpenConfirm(false);
        const kodetrans = `OH${moment().format("YYMMDDHHmmss")}`
        setOpenDataTrans({
            kodetrans: kodetrans,
            tanggal: moment(),
            status: "1"
        })
        mOpname.createTransOpname(clientID, token, kodeOutlet, tokenOutlet, kodetrans, moment()).then(resp => {
            if (resp) {
                setOpenOpnameDetail(true)
                setIsRefresh(true)
            } else {
                setOpenInfo(true)
                setDialogtext(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }

        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setOpenInfo(true)
                setDialogtext(`<p>Gagal Add Opname,  ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                setOpenInfo(true)
                setDialogtext(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        });
    }
    const handleDetail = (data) => {
        setOpenOpnameDetail(true)
        setOpenDataTrans({
            kodetrans: data.notrans,
            tanggal: moment(data.tglInput),
            status: data.status
        })
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />List Opname</h3>
                    <Button variant="contained" color="primary" onClick={konfirmasiaddTrans}><AddIcon />Add</Button>
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            {/* <div className="btnTable">
                                <Row>
                                    <Col>

                                    </Col>
                                </Row>
                            </div> */}
                            <div className={classes.tableWrapper}>
                                <Table
                                    className="table"
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {noData && (
                                            <TableRow style={{ height: 15 * emptyRows }}>
                                                <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                    <b>Tidak ada data</b>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {rows.length == 0 && !noData && loadingRow.map(el => (
                                            <TableRow
                                                key={el.toString()}
                                                style={{ height: 50 }}
                                            >
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell component="th" scope="row" >
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                            </TableRow>
                                        ))

                                        }
                                        {
                                            stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index}
                                                        >
                                                            <TableCell>
                                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, row)}><PageviewIcon /></Button>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                {row.notrans}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.tglInput).format("DD-MMM-YYYY HH:mm")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.userin}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.Statname}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 49 * emptyRows }}>
                                                <TableCell colSpan={7} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "previous page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label="Dense padding"
                        />
                    </div>
                </Col>
            </Row>
            {openInfo && (
                <DialogInfo open={openInfo} setOpen={setOpenInfo} text={dialogtext} />
            )}
            {openConfirm && (
                <DialogConfirm open={openConfirm} setOpen={setOpenConfirm} text={dialogtext} data={null} oke={addTrans} />
            )}
            {openOpnameDetail && (
                <OpnameDetail open={openOpnameDetail} setOpen={setOpenOpnameDetail} dataTrans={openDataTrans} isRefrest={setIsRefresh}/>
            )}
        </React.Fragment>
    );
}
export default Opname;
