import React, { useContext } from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { isBrowser, isMobile } from "react-device-detect";
import { Link } from 'react-router-dom'


const Home = (props) => {
  const { userLogin } = useContext(AuthContext);
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
        <p>Welcome {userLogin}</p>
        </Col>
      </Row>
      {isMobile && (
        <Row style={{ marginTop: '45px' }}>
          {/* <Col xs={6} lg>
            <Link to={`/pilih-outlet`}>
              <img className="img-fluid img-margin" alt="logo" width="auto" src={`/icon/${process.env.REACT_APP_COMPANY}/managemen_outlet-min.png`} />
            </Link>
          </Col>
          <Col xs={6} lg>
            <Link to={`/laporan/menu`}>
              <img className="img-fluid img-margin" alt="logo" width="auto" src={`/icon/${process.env.REACT_APP_COMPANY}/laporan-min.png`} />
            </Link>
          </Col> */}
        </Row>
      )}
    </React.Fragment>
  )
}

export default Home;