import GQL from '../../GQL';

const register = {
  register: (email, password, namaDepan, namaBelakang, nohp, nik, province, city,alamat, kodepos, gender, isReceiveMarketingEmail) => {
    console.log(email, password, namaDepan, namaBelakang, nohp, nik, province, city,alamat, kodepos, gender, isReceiveMarketingEmail)
    return new Promise((resolve, reject) => {
      const query = `
        mutation{
          createMember(input:{email:"${email}",namacustomer:"${''}",password:"${password}",alamat:"${alamat}",nik:"${nik}",gender:${gender},nohp:"${nohp}", domain:"${process.env.REACT_APP_DOMAIN}", emailCS:"${process.env.REACT_APP_EMAIL}", telpCS:"${process.env.REACT_APP_TELP}"})
        }
      `
      GQL(query, null, null).then(resp => {
        if(resp.errors) return(reject(resp.errors));;
        if(resp && resp.data && resp.data.createCustomer != null){
          return(resolve(resp.data.createCustomer));
        }else{
          return(reject(new Error("Gagal menyimpan data")))
        }
      }).catch(err => reject(err));
    })
  }
}

export default register;